.project-image-card {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;

    .card {
        border: none;
    }

    .gif {
        background-color: transparent;
        justify-content: center;

        img {
            width: auto;
        }
    }

    .flipped img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}