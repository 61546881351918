.project-web-gl-card {
    background-color: #16181b;
    margin-bottom: 30px;
    margin-top: 5px;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    min-width: 0;
    min-height: 0;
    display: inline-block;

    iframe{
        border-radius: 5px;
    }
}