.project-video-card {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;

    video {
        min-width: 100%;
        max-width: 100%;
    }
}