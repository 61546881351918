.project {
    
    .row>* {
        --bs-gutter-x: 1rem;
    }

    .grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}