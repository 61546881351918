.itch-download {
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0px;
    width: 100%;

    iframe {
        min-width: 100%;
        max-width: 100%;
        background-color: #212529;
    }
}